import { Action } from 'redux'
import { DEFAULT_LANGUAGE } from '../commons/constants'
import { Language } from '../commons/types'

export enum ActionType {
  ChangeLanguage = 'change-language',
}

export interface ChangeLanguage extends Action {
  type: ActionType.ChangeLanguage
  language: Language
}

export type AppAction = ChangeLanguage

export type State = {
  language: Language
}

export const initialState: State = {
  language: DEFAULT_LANGUAGE,
}

function rootReducer(state = initialState, action: AppAction): State {
  switch (action.type) {
    case ActionType.ChangeLanguage:
      return { ...state, language: action.language }
    default:
      return state
  }
}

export default rootReducer
